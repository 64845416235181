import { useKeycloak } from '@react-keycloak/web';
import {
  ScaleTelekomNavList,
  ScaleTelekomNavItem,
  ScaleIconUserFileUser,
  ScaleIconActionLogout,
  ScaleIconActionLightDarkMode,
} from '@telekom/scale-components-react';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import useAppContext from '../../hooks/useAppContext';

const FunctionNavigation = () => {
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();
  const { toggleTheme } = useAppContext();
  const isActive = (path) => pathname.startsWith(path) || undefined;

  return initialized ? (
    <ScaleTelekomNavList slot="functions" alignment="right" aria-label="Functions Menu">
      {!keycloak.authenticated && (
        <ScaleTelekomNavItem aria-label="Login" variant="functions">
          <Link to="#/" onClick={() => keycloak.login({ redirectUri: window.location.origin })} role="menuitem">
            <ScaleIconUserFileUser />
            <Typography>&nbsp;Login</Typography>
          </Link>
        </ScaleTelekomNavItem>
      )}

      {keycloak.authenticated && (
        <ScaleTelekomNavItem aria-label="myAccount" variant="functions" active={isActive('/account')}>
          <Link to="/account" role="menuitem">
            <ScaleIconUserFileUser />
            <Typography>
              &nbsp;
              {keycloak.idTokenParsed?.email}
            </Typography>
          </Link>
        </ScaleTelekomNavItem>
      )}

      {keycloak.authenticated && (
        <ScaleTelekomNavItem aria-label="Logout" variant="functions">
          <Link to="#/" onClick={() => keycloak.logout({ redirectUri: window.location.origin })} role="menuitem">
            <ScaleIconActionLogout />
            <Typography>&nbsp;Logout</Typography>
          </Link>
        </ScaleTelekomNavItem>
      )}

      <ScaleTelekomNavItem aria-label="Switch Mode" variant="functions">
        <Link to="#/" className="mode-switch" onClick={toggleTheme} role="menuitem">
          <ScaleIconActionLightDarkMode />
          {/* <Typography>&nbsp;Theme</T>>?ypography> */}
        </Link>
      </ScaleTelekomNavItem>
    </ScaleTelekomNavList>
  ) : null;
};


export default FunctionNavigation;
