/* eslint-disable react/prop-types */
import {
  createContext,
  useState,
  useEffect,
  useMemo,
} from 'react';


const AppContext = createContext();

const applyScaleMode = (theme) => {
  document.documentElement.dataset.mode = theme;
};

const getInitialTheme = () => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const systemTheme = mq.matches ? 'dark' : 'light';
  const localStorageTheme = localStorage.getItem('theme');
  return localStorageTheme || systemTheme || 'light';
};

const AppContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme);

  useEffect(() => {
    applyScaleMode(theme);

    const handleSystemChange = (e) => {
      const newSystemTheme = e.matches ? 'dark' : 'light';
      setTheme(newSystemTheme);
    };
    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    mq.addEventListener('change', handleSystemChange);

    return () => {
      mq.removeEventListener('change', handleSystemChange);
    };
  }, []);

  useEffect(() => {
    if (theme) {
      applyScaleMode(theme);
      localStorage.setItem('theme', theme);
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const contextValue = useMemo(() => ({ theme, toggleTheme }), [theme]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
