import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const http = axios.create();

http.interceptors.request.use((config) => {
  if (config.method !== 'get') {
    return {
      ...config,
      data: {
        ...config.data,
        transactionId: uuidv4(),
      },
    };
  }
  return config;
}, (error) => Promise.reject(error));

http.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.message) {
        return Promise.reject(error);
      }
      const filteredError = {
        status: error.response.status,
        message: error.response.data,
      };
      return Promise.reject(filteredError);
    }
    // nginx 400 scenario
    if (error.response.statusText) {
      return Promise.reject(error.response.statusText);
    }
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
});

export default http;
