import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ScaleModal } from '@telekom/scale-components-react';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import rbacCheck from '../../../rbac/rbacCheck';
import EditBusinessUnitForm from './EditBusinessUnitForm';
import DeleteForm from '../../../components/DeleteForm';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';
import { filterDataUpdates } from '../../../helpers/objects';

const BusinessUnitDetails = () => {
  const { businessUnitId } = useParams();
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const getBusinessUnitCall = useApiCall(Auth.fetchV1BusinessUnit);
  const updateBusinessUnitCall = useApiCall(Auth.updateV1BusinessUnit);
  const deleteBusinessUnitCall = useApiCall(Auth.deleteV1BusinessUnit);

  const [businessUnitDetails, setBusinessUnitDetails] = useState({});
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const canEditBusinessUnit = rbacCheck(userData.roles, '/business-units:PUT');
  const canDeleteBusinessUnit = rbacCheck(userData.roles, '/business-units:DELETE');

  const rows = [
    { title: 'Description', value: businessUnitDetails.description },
    {
      title: 'Organization',
      component: (
        <Link to={`/organizations/${businessUnitDetails.organizationId}`}>
          {businessUnitDetails.organization?.enterpriseName}
        </Link>
      ),
    },
    {
      title: 'BusinessUnitManager',
      component: (
        <Link to={`/users/${businessUnitDetails.businessUnitManager?.uuid}`}>
          {businessUnitDetails.businessUnitManager?.email}
        </Link>
      ),
    },
  ];

  const getBusinessUnitDetails = async () => {
    const [data] = await getBusinessUnitCall(businessUnitId);
    if (data) setBusinessUnitDetails(data);
  };

  const updateBusinessUnit = async (formValues) => {
    const updatedBusinessUnit = filterDataUpdates(businessUnitDetails, {
      ...formValues,
      businessUnitManager: formValues.businessUnitManager || null,
    });
    const [data, error] = await updateBusinessUnitCall({
      ...updatedBusinessUnit,
      _id: businessUnitId,
    });
    if (error) setEditModalOpened(false);
    if (data) {
      getBusinessUnitDetails();
      setEditModalOpened(false);
    }
  };

  const deleteBusinessUnit = async () => {
    const [data, error] = await deleteBusinessUnitCall(businessUnitId);
    if (error) setDeleteModalOpened(false);
    if (data) navigate(-1);
  };

  useEffect(() => {
    getBusinessUnitDetails();
  }, []);

  return (
    <>
      {editModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Edit Business Unit"
          onScale-close={() => setEditModalOpened(false)}
        >
          <EditBusinessUnitForm
            businessUnit={businessUnitDetails}
            onCancel={() => setEditModalOpened(false)}
            onSubmit={updateBusinessUnit}
          />
        </ScaleModal>
      )}
      {deleteModalOpened
        && (
        <ScaleModal
          opened
          size="small"
          heading="Delete Business Unit"
          onScale-close={() => setDeleteModalOpened(false)}
        >
          <DeleteForm
            fieldName="business unit name"
            fieldValue={businessUnitDetails.name}
            messages={['businessUnitDeleteConfirmation']}
            deleteFunc={deleteBusinessUnit}
            onClose={() => setDeleteModalOpened(false)}
          />
        </ScaleModal>
        )}
      <Grid
        className="content-with-header"
        container
        direction="column"
        rowGap={4}
      >
        <Grid item alignSelf="flex-start">
          <Breadcrumbs separator=" > ">
            <Link to="/organizations">organizations</Link>
            <Link to={`/organizations/${businessUnitDetails.organizationId}`}>{businessUnitDetails.organization?.enterpriseName}</Link>
            <Link to={`/organizations/${businessUnitDetails.organizationId}/business-units`}>bussiness units</Link>
            <Typography>{businessUnitDetails.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Container maxWidth="lg">
            <OverviewTable
              header={businessUnitDetails.name}
              rows={rows}
              editAction={{
                onClick: () => setEditModalOpened(true),
                disabled: !canEditBusinessUnit,
                tooltip: 'businessUnitEditPermissionErr',
              }}
              deleteAction={{
                onClick: () => setDeleteModalOpened(true),
                disabled: !canDeleteBusinessUnit,
                tooltip: 'businessUnitDeletePermissionErr',
              }}
            />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessUnitDetails;
